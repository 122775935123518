<template>
    <div>
        <div v-if="side === 'right'" class = "row mt-4">
            <div :class = "'w-100 col-sm-' + imgsize">
                <img class = "w-100" :src="require('@/assets/' + img + '')" alt="">
            </div>
            
            <div :class = "'mt-3 col-sm-' + textsize">
                <h2 class = "text-justify mb-4">{{title}}</h2>
                <p class = "text-justify mt-4">{{text1}}</p>
            </div>
        </div> 
        <div v-else-if="side === 'left'" class = "row mt-4 justify-content-end">
            <div :class = "'mt-3 col-sm-' + textsize">
                <h2 class = "mb-4 text-justify">{{title}}</h2>
                <p class = "mt-4 text-justify">{{text1}}</p>
            </div>
            <div :class = "'w-100 col-sm-' + imgsize">
                <img class = "w-100" :src="require('@/assets/' + img + '')" alt="">
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    props: ["title","text1","img","side","imgsize","textsize"],
    data() {
        return {
        };
    },
};
</script>
