<template>
    <div class = "container pb-5" style = "overflow:hidden;">
           <SectionTitle title="Detección por trampeo"/><br>

            <div class = "text-center">
                <img class = "w-100" src="@/assets/manejoPlagas/trampeo/image-1.jpg" alt="">
            </div>

           <TitleText 
            title = "DETECCIÓN POR TRAMPEO DE MOSCA DEL MEDITERRANEO EN GUATEMALA"
            text1 = "La detección por trampeo es utilizada para determinar la presencia o ausencia de la mosca del Mediterráneo en estado adulto, en un área determinada. Este método consiste en el establecimiento y mantenimiento de una red de trampeo efectiva y estratégicamente instalada; sujeta a revisiones periódicas de acuerdo a la importancia del área. Se utilizan diferentes tipos de trampas, dentro de las cuales están: Jackson, Panel Amarillo, Trampa Seca de Base Abierta (OBDT) o también conocida como Fase IV y C & C (Cook and Cunningham). El atrayente utilizado puede ser sexual, alimenticio y/o visual. Dentro de las trampas de atrayente sexual se encuentran la trampa Jackson, Panel Amarillo y C&C."
            numP = "1"
            />

            <ImagenTexto 
            title = "Trampa Jackson" 
            text1 = "La trampa Jackson está compuesta por un cartón encerado doblado en forma de prisma, un gancho de alambre que sirve de sostén de la trampa en el hospedante en donde se coloca un atrayente sexual sintético (feromona) específico para machos de Mosca del Mediterráneo. Este atrayente, en su presentación sólida se encuentra dentro de un polímetro, el cual se va liberando lentamente y dependiendo de las condiciones ambientales, su cambio puede ser de 6 a 8 semanas. Este atrayente  sólido se coloca dentro de una canastilla especial, el cual se fija en el gancho de la trampa."
            img = "manejoPlagas/trampeo/image-3.jpg"
            imgsize = "3"
            textsize = "8"
            side = "right"
            />

            <p class = "mt-4 text-justify">El otro componente de la trampa Jackson, es el inserto o laminilla, el cual es un cartón recubierto con un material que aumenta su durabilidad en el campo, donde en uno de los lados se le aplica una capa delgada de pegamento, que es donde se van a quedar las moscas del Mediterráneo pegadas, el cual se cambia al revisar la trampa, y debe ser trasladado al laboratorio doblado de una forma que no permita que se dañen las moscas dentro de la laminilla. La trampa Jackson ha sido ampliamente utilizada ya que su costo es bajo, y es eficiente en la captura de machos de la mosca del Mediterráneo. Esta trampa se puede determinar las poblaciones silvestres de la mosca del Mediterráneo y evaluar la liberación de la mosca estéril en campo, también se utilizan para evaluar medidas de control.</p>

            <ImagenTexto 
            title = "Trampa Panel Amarillo" 
            text1 = "La trampa Panel Amarillo está compuesta por un cartón amarillo con una capa delgada de pegamento el cual es doblado por la mitad, quedando los dos lados exteriores con pegamento. Se utiliza un atrayente sexual sintético (feromona), en presentación líquida el cual se mezcla con el pegamento a razón de 13 gramos de pegamento por 2 gramos de atrayente. Es una trampa que captura machos, y además de la atracción sexual, se tiene la atracción visual por el color amarillo y que tiene una mayor superficie de captura. La liberación del atrayente es en forma rápida, por lo que se utiliza en áreas post supresión de la plaga y en áreas libres de mosca del Mediterráneo, donde se requiere de trampas de mayor sensibilidad, como medida preventiva."
            img = "manejoPlagas/trampeo/image-2.jpg"
            imgsize = "3"
            textsize = "9"
            side = "left"
            />

            <ImagenTexto 
            title = "Trampa C&C" 
            text1 = "La trampa C&C Consiste de dos paneles amarillos con una capa fina de pegamento, doblados por la mitad, donde al final quedan cuatro caras con pegamento y en medio de estas se encuentra un panel de atrayente sexual sintético (feromona)  sólido, con un contenido de 10 gramos. Los tres paneles están sujetos a una base que tiene un gancho en la parte superior. La liberación del atrayente  es de forma lenta, por lo que puede estar en el campo hasta por 13 semanas sin necesidad de cambiar el panel de atrayente. Cuando la trampa se revisa se deben cambiar los dos paneles amarillos que deben contener la misma información y solo diferenciarlos por una letra. Esta trampa se puede utilizar para monitorear y detectar incursiones de poblaciones muy bajas de la mosca del Mediterráneo."
            img = "manejoPlagas/trampeo/image-4.jpg"
            imgsize = "3"
            textsize = "9"
            side = "right"
            />

            <p class = "mt-4 text-justify">Cuando la trampa se revisa se deben cambiar los dos paneles amarillos que deben contener la misma información y solo diferenciarlos por una letra. Esta trampa se puede utilizar para monitorear y detectar incursiones de poblaciones muy bajas de la mosca del Mediterráneo.</p>

            <ImagenTexto 
            title = "Trampa Fase IV" 
            text1 = "Dentro de las trampas de atrayente alimenticio se encuentra la trampa fase IV, la cual se compone de un cilindro con fondo abierto de cartón de color verde recubierto con un plástico para aumentar su duración en el campo; la parte superior es de plástico transparente y en los extremos tiene tres agujeros equidistantes a lo largo de la circunferencia del cilindro entre los dos extremos, en la parte interior se colocan los atrayentes alimenticios que son: Putrecina, Trimetilamina y Acetato de Amonio, en medio del cilindro sujeto por el gancho se coloca el inserto o laminilla con pegamento."
            img = "manejoPlagas/trampeo/image-5.jpg"
            imgsize = "3"
            textsize = "9"
            side = "left"
            />

            <p class = "mt-4 text-justify">Debido al tipo de atrayente se capturan hembras en mayor proporción, ya que los atrayentes van pegados dentro del cilindro (cartón), se cambian junto con estos y puede ser cada cuatro semanas o cuando ya no se encuentren en buen estado. Al darle servicio a la trampa y al no encontrarse uno de los atrayentes, este debe ser reemplazado, por lo cual se debe tener un orden al momento de la colocación y así saber cuál es el que se va a reemplazar. Esta trampa se recomienda principalmente en áreas de liberación de insecto estéril, debido a que presenta una menor recaptura de machos. La instalación de una red de trampeo va a estar determinada por las características del área, la disponibilidad de hospedantes, su distribución y su fenología. Para la instalación de la trampa se debe seleccionar el lugar donde exista una mayor probabilidad de captura de mosca del Mediterráneo. </p>
            <p class = "mt-4 text-justify">Después de haber seleccionado el lugar para la instalación de la trampa, se realiza la georreferenciación de la trampa, lo cual se puede hacer con la ayuda de las hojas cartográficas o con GPS. Posteriormente, se debe identificar la trampa y describir las características del lugar. Al trasladar esta información al departamento de informática se le asigna un Código Único. La información de la trampa debe incluir nombre del revisor, coordenada, ruta, correlativo, cuadrante, municipio, altura sobre el nivel del mar, uso de la tierra, referencia y otra información que sea requerida. El servicio de la trampa se debe de hacer de acuerdo a la estrategia establecida y puede ser cada siete o catorce días, cambiando la laminilla y dependiendo del estado de la trampa al requerirlo debe ser cambiada, también hay que observar el estado del atrayente. Para mantener la alta eficiencia de la trampa, cuando lo amerite, debe ser rotada de acuerdo a la disponibilidad del hospedante, y en algunos casos se debe trasladar a otro lugar. Cuando no hay hospedantes se elimina la trampa.</p>
            <p class = "mt-4 text-justify">La señalización de la trampa en el campo se debe de hacer utilizando una señal guía, la cual se debe observar sobre el camino o vereda y desde esta, se debe observar la señal del hospedante, la cual debe quedar a menos de tres metros de la trampa. Las señales son tiras de 10cm de ancho y 70 cm. de largo de nylon color blanco. Después de obtener el código único de la trampa, se deben elaborar los mapas de trampeo y los croquis, para una fácil ubicación de las trampas. Con el tiempo este código único de la trampa sirve para obtener la información histórica de la trampa, en otras palabras, el código único es el nombre propio de la trampa.</p>
            <p class = "mt-4 text-justify">Para hacer comparaciones de niveles poblacionales de la plaga en espacio y tiempo, donde se puede tener diferentes densidades de trampeo y variaciones en los días de exposición, se utiliza el M.T.D, que es moscas capturadas por trampa y por día, el cual puede ser fértil o estéril, y por cada tipo de trampa. Una detección oportuna, permite un control oportuno y que se pueda alcanzar la erradicación de la mosca del Mediterráneo, que es el objetivo principal del Programa MOSCAMED.</p>

            <div class = "text-center">
                <img class = "w-100" src="@/assets/manejoPlagas/trampeo/image-6.jpg" alt="">
            </div>
    </div>
</template>

<script>
import TitleText from "@/components/TitleText.vue";
import ImagenTexto from "@/components/ImagenTexto.vue";

export default {
    name: "Home",
    components: {
        TitleText,
        ImagenTexto,
    },
};
</script>
