<template>
    <div>
        <h3 class = "text-justify mt-4">{{title}}</h3>
        <p class = "text-justify mt-4">{{text1}}</p>
        <p v-if="numP > 1" class = "text-justify mt-4">{{text2}}</p>
        <p v-if="numP > 2" class = "text-justify mt-4">{{text3}}</p>
    </div>
</template>

<script>
export default {
    props: ["title","text1","text2","text3","numP"],
    data() {
        return {
        };
    },
};
</script>